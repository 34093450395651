import { useState } from 'react';
import { isFulfilled } from '@reduxjs/toolkit';
import { Alert, Button, Checkbox, Select } from '@sgme/ui';

import type { BreachCommentPost, BreachTypePatch } from '@/types/model/ws/generatedModel.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { uiSlice, type SelectedBreachId } from '@/store/slices/uiSlice.ts';
import { patchBreach, postComment } from '@/store/async-thunks/api-thunks.ts';
import { DialogModal } from '@/web/components/bootstrap/modal/DialogModal.tsx';
import { useModal } from '@/web/components/bootstrap/modal/ModalContext.tsx';
import { TextInput } from '@/web/components/bootstrap/TextInput.tsx';
import {
  breachTypes,
  selectBreachType,
  useCommentFormState,
} from '@/web/components/guardian/useCommentFormState.tsx';

export function BulkCloseButton() {
  const modalApi = useModal(() => <BulkCommentModal />);
  const hasPermission = useAppSelector(state =>
    apiSlice.selectors.userHasPermission(state, 'WRITE'),
  );
  const selectedBreachIds = useAppSelector(uiSlice.selectors.selectedBreaches);

  if (!hasPermission || selectedBreachIds.length === 0) {
    return null;
  }

  return (
    <button
      className="btn btn-default btn-icon-end"
      type="button"
      onClick={() => {
        modalApi.showModal();
      }}
    >
      Bulk comment
      <i className="icon">chat</i>
    </button>
  );
}

function BulkCommentModal() {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const modalApi = useModal();
  const selectedBreachIds = useAppSelector(uiSlice.selectors.selectedBreaches);

  const byAlertId = Object.groupBy(selectedBreachIds, b => b.alertId);
  const [state, stateMethods] = useCommentFormState();

  async function patchBreachDispatch(
    body: BreachTypePatch,
    { alertId, breachId }: SelectedBreachId,
  ): Promise<void> {
    const result = await dispatch(patchBreach({ alertId, breachId, body }));
    if (!isFulfilled(result)) {
      throw result;
    }
  }

  async function postCommentDispatch(
    body: BreachCommentPost,
    { alertId, breachId }: SelectedBreachId,
  ): Promise<void> {
    await dispatch(postComment({ alertId, breachId, body }));
  }

  async function handleSubmit() {
    if (state.comment === '') {
      return;
    }

    setLoading(true);

    try {
      for (const selectedBreachId of selectedBreachIds) {
        if (state.closingComment) {
          await patchBreachDispatch({ breachType: selectBreachType(state) }, selectedBreachId);
        }
        await postCommentDispatch(
          {
            comment: state.comment,
            closingComment: state.closingComment,
          },
          selectedBreachId,
        );
      }
    } finally {
      setLoading(false);
      dispatch(uiSlice.actions.clearSelectedBreaches());
      modalApi.closeModal();
    }
  }

  return (
    <DialogModal
      header="Bulk close"
      footer={
        <>
          <Button onClick={() => modalApi.closeModal()}>Cancel</Button>
          <Button
            loading={loading}
            disabled={state.comment === ''}
            purpose="primary"
            onClick={handleSubmit}
          >
            Apply
          </Button>
        </>
      }
    >
      <div className="d-flex flex-column gap-2">
        <Alert icon="info" color="info">
          Bulk on {selectedBreachIds.length} breaches in {Object.keys(byAlertId).length} alerts
        </Alert>
        <article className="d-flex flex-column border-top pt-8px gap-8px">
          <textarea
            className="form-control"
            value={state.comment}
            onChange={e => stateMethods.setComment(e.target.value)}
          />
          <div className="d-flex gap-1">
            {state.closingComment && (
              <Select itemsAsObjects={breachTypes} onChange={stateMethods.setBreachType} />
            )}
            {state.closingComment && state.breachType === 'Other' && (
              <TextInput
                placeholder="Breach type..."
                name="breach-type"
                wrapperClassName="breach-type-input"
                maxLength={20}
                type="text"
                onChange={e => stateMethods.setFreeText(e.target.value)}
              />
            )}
          </div>

          <div className="flex-between">
            <Checkbox
              checked={state.closingComment}
              onCheckBoxChange={closingComment => stateMethods.setClosing(closingComment)}
              label="Closing comment"
            />
          </div>
        </article>
      </div>
    </DialogModal>
  );
}
