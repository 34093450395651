import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { assertUnreachable } from '@sgme/fp';

import type {
  Alert,
  AlertStatus,
  Breach,
  BreachStatus,
  EventDto,
} from '@/types/model/ws/generatedModel.ts';
import { sortByDesc } from '@/core/utils/sortBy.ts';
import { fetchAlerts, fetchBreaches, fetchEvents } from '@/store/async-thunks/ws-thunks.ts';

type SelectedDetails = {
  breachId?: number;
  alertId?: number;
};

export interface MainState {
  alerts: Alert[];
  breachesByAlertId: Record<number, Breach[]>;
  eventsByBreachId: Record<number, EventDto[]>;
  selectedDetails?: SelectedDetails;
}

const initialState: MainState = {
  alerts: [],
  breachesByAlertId: {},
  eventsByBreachId: {},
};

const breachesByAlertId: (state: MainState, alertId: number) => Breach[] = createSelector(
  [(state: MainState) => state.breachesByAlertId, (_, alertId: number) => alertId],
  (breachesByAlertId, alertId) => {
    return breachesByAlertId[alertId] ?? [];
  },
);

const breachById: (state: MainState, alertId?: number, breachId?: number) => Breach | undefined =
  createSelector(
    [
      (state: MainState) => state.breachesByAlertId,
      (_, alertId?: number) => alertId,
      (_, _2, breachId?: number) => breachId,
    ],
    (breachesByAlertId, alertId, breachId) => {
      if (breachId === undefined || alertId === undefined) {
        return undefined;
      }
      const breaches = breachesByAlertId[alertId];
      return breaches.find(b => b.id === breachId);
    },
  );

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    updateAlert: (state, action: PayloadAction<Alert>) => {
      const alertToUpdateIndex = state.alerts.findIndex(alert => alert.id === action.payload.id);
      if (alertToUpdateIndex !== -1) {
        state.alerts[alertToUpdateIndex] = action.payload;
      } else {
        state.alerts.push(action.payload);
      }
      state.alerts.sort(alertSortFn);
    },
    updateBreach: (state, action: PayloadAction<Breach>) => {
      const currentBreaches = state.breachesByAlertId[action.payload.alertId];
      if (currentBreaches === undefined) {
        console.warn(
          `Tried to update breach for non initialized alertId ${action.payload.alertId}`,
        );
        return;
      }

      const alertToUpdateIndex = currentBreaches.findIndex(
        breach => breach.id === action.payload.id,
      );
      if (alertToUpdateIndex !== -1) {
        currentBreaches[alertToUpdateIndex] = action.payload;
      } else {
        currentBreaches.push(action.payload);
      }
      currentBreaches.sort(breachSortFn);
    },
    updateEvent: (state, action: PayloadAction<EventDto>) => {
      const currentEvents = state.eventsByBreachId[action.payload.breachId];
      if (currentEvents === undefined) {
        console.warn(
          `Tried to update event for non initialized breachId ${action.payload.breachId}`,
        );
        return;
      }

      const alertToUpdateIndex = currentEvents.findIndex(
        breach => breach.sequence === action.payload.sequence,
      );
      if (alertToUpdateIndex !== -1) {
        currentEvents[alertToUpdateIndex] = action.payload;
      } else {
        currentEvents.push(action.payload);
      }
      currentEvents.sort(eventSortFn);
    },
    setSelectedDetails(state, action: PayloadAction<SelectedDetails>) {
      state.selectedDetails = action.payload;
    },
  },
  selectors: {
    alerts: state => state.alerts,
    breachesByAlertId: breachesByAlertId,
    selectedDetails: state => state.selectedDetails,
    breach: breachById,
    currentEvents: createSelector(
      [(state: MainState) => state.eventsByBreachId, (state: MainState) => state.selectedDetails],
      (eventsByBreachId, selectedDetails) => {
        if (selectedDetails === undefined || selectedDetails.breachId === undefined) {
          return [];
        }
        return eventsByBreachId[selectedDetails.breachId];
      },
    ),
  },
  extraReducers: builder => {
    builder.addCase(fetchAlerts.fulfilled, (state, action) => {
      state.alerts = action.payload.toSorted(alertSortFn);
    });
    builder.addCase(fetchBreaches.fulfilled, (state, action) => {
      const { breaches, alertId } = action.payload;
      state.breachesByAlertId[alertId] = breaches.toSorted(breachSortFn);
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      const { events, breachId } = action.payload;
      state.eventsByBreachId[breachId] = events.toSorted(eventSortFn);
    });
  },
});

const alertSortFn = sortByDesc<Alert>([x => statusVal(x.status), x => x.date, x => x.id]);
const breachSortFn = sortByDesc<Breach>([x => statusVal(x.status), x => x.startInstant, x => x.id]);
const eventSortFn = sortByDesc<EventDto>([x => x.sequence]);

function statusVal(status: BreachStatus | AlertStatus): number {
  switch (status) {
    case 'OPEN':
      return 1;
    case 'STANDBY':
      return 0.5;
    case 'CLOSE':
      return 0;
    default:
      assertUnreachable(status, `Unknown status ${status}`);
  }
}
