import 'allotment/dist/style.css';
import '../../index.css';

import { Loader } from '@sgme/ui';
import { SgwtWebAnalytics } from '@sgwt/sgwt-widgets-react';
import { createRouter, RouterProvider } from '@tanstack/react-router';

import { getConfig } from '@/core/config/config.ts';
import { ModalProvider } from '@/web/components/bootstrap/modal/ModalContext.tsx';
import { PermissionModal } from '@/web/components/bootstrap/PermissionModal.tsx';
import { DefaultErrorRenderer } from '@/web/components/common/DefaultErrorRenderer.tsx';
import { IntlProvider } from '@/web/components/sgwt/IntlContext.tsx';
import { routeTree } from '@/web/generated/routeTree.gen.ts';

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <Loader fullScreen />,
  defaultErrorComponent: DefaultErrorRenderer,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  const { matomo } = getConfig();

  return (
    <IntlProvider>
      <div className="app">
        <PermissionModal />

        <ModalProvider>
          <RouterProvider router={router} />
        </ModalProvider>

        <SgwtWebAnalytics
          siteId={matomo.siteId}
          baseUrl={matomo.url}
          debug={false}
          disabled={import.meta.env.DEV}
        />
      </div>
    </IntlProvider>
  );
}
