import { Button, Tooltip } from '@sgme/ui';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';

import { AccountCenter } from '@/web/components/sgwt/AccountCenter.tsx';

export function Header(): JSX.Element {
  return (
    <header className="navbar">
      <div className="navbar-title">
        <a className="navbar-title-link" href="/">
          <div className="navbar-logo">
            <svg
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="SG Logo"
            >
              <rect x="0" y="0" width="32" height="32" fill="white" />
              <rect x="1" y="1" width="30" height="15" fill="var(--bs-socgen)" />
              <rect x="1" y="16" width="30" height="15" fill="black" />
              <rect x="6" y="15" width="20" height="2" fill="white" />
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            Agency&nbsp;Flow
            <br />
            Monitoring
          </div>
        </a>
      </div>

      <div className="navbar-toolbar">
        <HelpRequestButton />
        <AccountCenter />
      </div>
    </header>
  );
}

export function HelpRequestButton(): JSX.Element {
  const { sgwtHelpCenter } = useSgwtWidgets();
  return (
    <Tooltip contents="Request Help">
      <Button
        iconPosition="icon-only"
        purpose="flat"
        size="lg"
        className="ms-1"
        onClick={() => sgwtHelpCenter?.form()}
      >
        <em className="icon">feedback</em>
      </Button>
    </Tooltip>
  );
}
