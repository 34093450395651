import { createAsyncThunk } from '@reduxjs/toolkit';

import type { PatchBreachParams, PostCommentParams } from '@/types/model/api.ts';
import type {
  CompanyDto,
  CompanyResult,
  MarketDto,
  MarketResult,
} from '@/types/model/ws/generatedModel.ts';
import { webApi } from '@/core/api/webApi.ts';
import type { AppState } from '@/store/store.ts';
import type { CurrentUser } from '@/store/slices/apiSlice.ts';

export const fetchCompanies = createAsyncThunk<CompanyDto[], void, { state: AppState }>(
  'api/fetchCompanies',
  async (_, { getState }) => {
    const payload = await webApi.get('api/v1/guardian/companies').json<CompanyResult>();
    return payload.companies;
  },
);
export const fetchMarkets = createAsyncThunk<MarketDto[], void, { state: AppState }>(
  'api/fetchMarkets',
  async (_, { getState }) => {
    const payload = await webApi.get('api/v1/guardian/markets').json<MarketResult>();
    return payload.markets;
  },
);

export const postComment = createAsyncThunk<void, PostCommentParams, { state: AppState }>(
  'api/postComment',
  async ({ alertId, breachId, body }, { getState }) => {
    return webApi
      .post(`api/v1/guardian/alerts/${alertId}/breaches/${breachId}/comments`, {
        json: body,
      })
      .json();
  },
);

export const patchBreach = createAsyncThunk<void, PatchBreachParams, { state: AppState }>(
  'api/patchBreach',
  async ({ alertId, breachId, body }, { getState }) => {
    return webApi
      .patch(`api/v1/guardian/alerts/${alertId}/breaches/${breachId}`, {
        json: body,
      })
      .json();
  },
);

export const fetchCurrentUser = createAsyncThunk<CurrentUser, void, { state: AppState }>(
  'user/fetchCurrentUser',
  async () => {
    return webApi.get('api/current-user', { timeout: 10000 }).json<CurrentUser>();
  },
);
