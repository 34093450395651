import { createRootRoute, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';

import { Toasts } from '@/web/components/bootstrap/NotificationsContainer.tsx';
import { Footer } from '@/web/components/layout/Footer.tsx';
import { Header } from '@/web/components/layout/Header.tsx';

export const Route = createRootRoute({
  component: () => {
    return (
      <>
        <Toasts />
        <Header />

        <main>
          <Outlet />
        </main>

        <Footer />
        {import.meta.env.DEV && <TanStackRouterDevtools />}
      </>
    );
  },
});
