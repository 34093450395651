import { configureStore, type ThunkAction, type UnknownAction } from '@reduxjs/toolkit';

import { apiSlice } from '@/store/slices/apiSlice.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { uiSlice } from '@/store/slices/uiSlice.ts';
import { listenerMiddleware } from '@/store/listenerMiddleware.ts';
import { startErrorActionListener } from '@/store/startErrorActionListener.ts';

export const reducer = {
  main: mainSlice.reducer,
  api: apiSlice.reducer,
  ui: uiSlice.reducer,
};
export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppStore = typeof store;
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, unknown, UnknownAction>;

startErrorActionListener();
