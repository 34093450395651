import { useMethods } from '@/web/components/hooks/useMethods.ts';

export const breachTypes = ['Limit calibration', 'Genuine Alert', 'Technical', 'Other'] as const;
export type BreachType = (typeof breachTypes)[number];

interface DefaultClosingState {
  closingComment: true;
  breachType: Exclude<BreachType, 'Other'>;
  comment: string;
}

interface FreeClosingState {
  closingComment: true;
  breachType: 'Other';
  comment: string;
  freeTextType: string;
}

export interface CommentState {
  closingComment: false;
  comment: string;
}

export type ClosingState = FreeClosingState | DefaultClosingState;
type FormData = ClosingState | CommentState;

export function selectCanPost(state: FormData, loading: boolean): boolean {
  return state.comment !== '' && !loading;
}

export function selectBreachType(state: FormData): string {
  if (!state.closingComment) {
    return '';
  }
  if (state.breachType === 'Other') {
    return state.freeTextType;
  }
  return state.breachType;
}

const initialState: FormData = {
  comment: '',
  closingComment: false,
};

function createMethods(state: FormData) {
  return {
    setComment(comment: string): FormData {
      return { ...state, comment };
    },
    setClosing(closingComment: boolean): FormData {
      if (closingComment) {
        return {
          ...state,
          closingComment,
          breachType: 'Limit calibration',
        };
      }
      return {
        ...state,
        closingComment,
      };
    },
    setBreachType(breachType: BreachType): FormData {
      if (breachType === 'Other') {
        return {
          ...state,
          breachType,
          freeTextType: '',
        };
      }
      return {
        ...state,
        breachType,
      };
    },
    setFreeText(freeTextType: string): FormData {
      return {
        ...state,
        freeTextType,
      } as FormData;
    },
    clearForm(): FormData {
      return initialState;
    },
  } as const;
}

export function useCommentFormState() {
  return useMethods(createMethods, initialState);
}
