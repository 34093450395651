import { useSyncExternalStore } from 'react';

import { SnoozeManager } from '@/web/components/guardian/snooze/SnoozeManager.ts';

type SnoozeSetter = (date: string, durationMinutes: number, snoozed: boolean) => void;

export function useSnoozed(alertId: number, breachId: number): [boolean, SnoozeSetter] {
  const snoozeManager = SnoozeManager.instance();
  function isSnoozed() {
    return snoozeManager.isSnoozed(alertId, breachId, new Date().toISOString());
  }

  function subscribe(callback: () => void) {
    snoozeManager.eventEmitter.on('snoozeChanged', callback);
    return () => {
      snoozeManager.eventEmitter.off('snoozeChanged', callback);
    };
  }

  return [
    useSyncExternalStore(subscribe, isSnoozed, isSnoozed),
    (date, durationMinutes, snoozed) => {
      snoozeManager.setSnooze(alertId, breachId, date, durationMinutes, snoozed);
    },
  ];
}
