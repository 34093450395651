import { addMinutes, format, parseISO } from 'date-fns';

export function formatDateTime(value: number | string | Date) {
  return `${format(value, 'dd LLL yyyy HH:mm:ss')} (${getTimezoneOffset()})`;
}

export function formatDate(value: number | string | Date) {
  return `${format(value, 'dd LLL yyyy')} (${getTimezoneOffset()})`;
}

function getTimezoneOffset(): string {
  const timezoneOffset = -new Date().getTimezoneOffset() / 60;
  if (timezoneOffset > 0) {
    return `UTC+${timezoneOffset}`;
  }
  return `UTC${timezoneOffset}`;
}

export function formatDateTimeUtc(dateString: string): string {
  const date = parseISO(dateString);
  const dateWithOffset = adjustForUTCOffset(date);
  return format(dateWithOffset, 'yyyy-MM-dd HH:mm:ss');
}

export function adjustForUTCOffset(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}
