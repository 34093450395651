import { createSlice, nanoid, type PayloadAction } from '@reduxjs/toolkit';
import { assertUnreachable } from '@sgme/fp';

import type { IntlMessage } from '@/types/intl';
import type { GuiNotification } from '@/types/model/ws/model.ts';
import type { AppThunk } from '@/store/store.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { SnoozeManager } from '@/web/components/guardian/snooze/SnoozeManager.ts';

export type ToastSeverity = 'error' | 'info';
export type IntlMessageOrString = IntlMessage | string;

export interface BreachLink {
  alertId?: number;
  breachId?: number;
}

export interface ToastMessage {
  id: string;
  title: IntlMessageOrString;
  body?: IntlMessageOrString;
  link?: BreachLink;
  time: string;
  severity: ToastSeverity;
}

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ToastMessagePayload = PartialBy<Omit<ToastMessage, 'id' | 'time'>, 'title'>;

export type SelectedBreachId = { alertId: number; breachId: number };

interface UiState {
  toastMessages: ToastMessage[];
  selectedBreaches: SelectedBreachId[];
}

const initialState: UiState = {
  toastMessages: [],
  selectedBreaches: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    clearSelectedBreaches: state => {
      state.selectedBreaches = [];
    },
    toggleBreach: (
      state,
      action: PayloadAction<{ breachId: SelectedBreachId; selected: boolean }>,
    ) => {
      const selectedBreachIndex = state.selectedBreaches.findIndex(selectedBreach => {
        return JSON.stringify(selectedBreach) === JSON.stringify(action.payload.breachId);
      });
      const isPresent = selectedBreachIndex > -1;
      if (!action.payload.selected && isPresent) {
        state.selectedBreaches.splice(selectedBreachIndex, 1);
      } else if (!isPresent && action.payload.selected) {
        state.selectedBreaches.push(action.payload.breachId);
      }
    },
    addToast: (state, action: PayloadAction<ToastMessagePayload>) => {
      state.toastMessages.push({
        ...action.payload,
        title: action.payload.title ?? action.payload.severity,
        id: nanoid(),
        time: new Date().toISOString(),
      });
    },
    deleteToast: (state, action: PayloadAction<string>) => {
      state.toastMessages = state.toastMessages.filter(
        toastMessage => toastMessage.id !== action.payload,
      );
    },
  },
  selectors: {
    toasts: state => state.toastMessages,
    selectedBreaches: state => state.selectedBreaches,
    breachSelected: (state, breachId: SelectedBreachId) => {
      return state.selectedBreaches.some(selectedBreach => {
        return JSON.stringify(selectedBreach) === JSON.stringify(breachId);
      });
    },
    countBreachesSelected: (state, alertId: number) => {
      return state.selectedBreaches.reduce((sum, selectedBreach) => {
        if (selectedBreach.alertId === alertId) {
          return sum + 1;
        }
        return sum;
      }, 0);
    },
  },
});

export function toggleAllBreachesThunk(alertId: number, selected: boolean): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const breaches = mainSlice.selectors.breachesByAlertId(state, alertId);
    for (const breach of breaches) {
      const breachId = breach.id;
      const isClosed = breach.comments.some(c => c.closingComment);
      dispatch(
        uiSlice.actions.toggleBreach({
          breachId: { alertId, breachId },
          selected: selected && !isClosed,
        }),
      );
    }
  };
}

export function handleGuiNotificationThunk(guiNotification: GuiNotification): AppThunk {
  return async dispatch => {
    const { alertId, breachId } = guiNotification;
    const snoozed = SnoozeManager.instance().isSnoozed(alertId, breachId, new Date().toISOString());
    console.log('Received notification', guiNotification, `snoozed=${snoozed}`);
    if (snoozed) {
      return;
    }

    switch (guiNotification.type) {
      case 'DESKTOP_NOTIFICATION': {
        sendNotification(
          guiNotification.title,
          { alertId, breachId },
          {
            body: guiNotification.body,
            // group notifications to avoid spam
            tag: `${alertId}-${breachId}`,
          },
        );
        break;
      }
      case 'TOAST': {
        const body = guiNotification.body;
        const toast: ToastMessagePayload = {
          body: body,
          link: { alertId, breachId },
          title: guiNotification.title,
          severity: 'info',
        };
        dispatch(uiSlice.actions.addToast(toast));
        break;
      }
      default:
        assertUnreachable(
          guiNotification.type,
          `Unhandled GUI Notification type ${guiNotification.type}`,
        );
    }

    if (guiNotification.sound !== undefined) {
      const sound = document.getElementById(guiNotification.sound) as HTMLMediaElement;
      if (sound !== null) {
        sound.pause();
        sound.currentTime = 0;
        await sound.play();
      } else {
        console.warn(`Cannot play sound ${guiNotification.sound}`);
      }
    }
  };
}

function sendNotification(message: string, link?: BreachLink, options?: NotificationOptions) {
  const notification = new Notification(message, options);
  notification.addEventListener('click', () => {
    if (link !== undefined && link.alertId !== undefined) {
      const search = new URLSearchParams();
      if (link.alertId !== undefined) {
        search.append('alertId', link.alertId.toString());
      }
      if (link.breachId !== undefined) {
        search.append('breachId', link.breachId.toString());
      }

      window.focus();
      history.replaceState(null, '', `/?${search}`);
    }
  });
}
