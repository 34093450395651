import { Button } from '@sgme/ui';
import clsx from 'clsx';

import { useModal } from '@/web/components/bootstrap/modal/ModalContext.tsx';
import { SnoozeModal } from '@/web/components/guardian/snooze/SnoozeModal.tsx';
import { useSnoozed } from '@/web/components/guardian/snooze/useSnoozed.tsx';

interface SnoozeButtonProps {
  alertId: number;
  breachId: number;
}

export function SnoozeButton({ alertId, breachId }: SnoozeButtonProps) {
  const [snoozed, setSnoozed] = useSnoozed(alertId, breachId);

  function toggleSnooze() {
    if (snoozed) {
      setSnoozed(new Date().toISOString(), 0, false);
    } else {
      modalApi.showModal();
    }
  }

  const modalApi = useModal(() => (
    <SnoozeModal
      onSnooze={(durationMinutes, snoozed) => {
        setSnoozed(new Date().toISOString(), durationMinutes, snoozed);
      }}
    />
  ));

  return (
    <>
      <Button
        className={clsx('w-100', { 'text-secondary': snoozed })}
        size="sm"
        purpose="flat"
        onClick={toggleSnooze}
        iconPosition="icon-only"
      >
        <em className="icon">{snoozed ? 'add_alert' : 'notifications_paused'}</em>
      </Button>
    </>
  );
}
