import type { ICellRendererParams } from '@ag-grid-community/core';

import type { WorstSeverity } from '@/types/model/ws/generatedModel.ts';

export function SeverityRenderer({ value }: ICellRendererParams<unknown, WorstSeverity>) {
  switch (value) {
    case 'NOTICE':
      return <div className="badge badge-sm rounded-pill badge-discreet-light">{value}</div>;
    case 'LOW':
      return <div className="badge badge-sm rounded-pill badge-discreet-info">{value}</div>;
    case 'MEDIUM':
    case 'MEDIUM_HIGH':
      return <div className="badge badge-sm rounded-pill badge-discreet-warning">{value}</div>;
    case 'HIGH':
      return <div className="badge badge-sm rounded-pill badge-discreet-danger">{value}</div>;
    default:
      return <div className="badge badge-sm rounded-pill badge-discreet-light">{value}</div>;
  }
}
