import { createAsyncThunk } from '@reduxjs/toolkit';

import type { Alert, Breach, EventDto } from '@/types/model/ws/generatedModel.ts';
import {
  isAlertNotification,
  isBreachNotification,
  isEventNotification,
} from '@/types/model/ws/response.ts';
import type { IWebsocketConnection } from '@/core/ws/WebsocketConnection.ts';
import type { AppState } from '@/store/store.ts';

export const fetchAlerts = createAsyncThunk<
  Alert[],
  { wsConnection: IWebsocketConnection },
  { state: AppState }
>('main/fetchAlerts', async ({ wsConnection }, { getState }) => {
  const eventImagePayload = await wsConnection.subscribeAndWaitForImage(
    { subject: 'ALERT' },
    isAlertNotification,
  );
  return eventImagePayload.payload.alerts;
});

export const fetchBreaches = createAsyncThunk<
  { alertId: number; breaches: Breach[] },
  { alertId: number; wsConnection: IWebsocketConnection },
  { state: AppState }
>('main/fetchBreaches', async ({ alertId, wsConnection }, { getState }) => {
  const eventImagePayload = await wsConnection.subscribeAndWaitForImage(
    { subject: 'BREACH', alertId },
    isBreachNotification,
  );
  return { alertId, breaches: eventImagePayload.payload.breaches };
});

export const fetchEvents = createAsyncThunk<
  { breachId: number; events: EventDto[] },
  { breachId: number; wsConnection: IWebsocketConnection },
  { state: AppState }
>('main/fetchEvents', async ({ breachId, wsConnection }, { getState }) => {
  const eventImagePayload = await wsConnection.subscribeAndWaitForImage(
    { subject: 'EVENT', breachId },
    isEventNotification,
  );
  return { breachId, events: eventImagePayload.payload.events };
});
