import { forwardRef, useRef } from 'react';

import type { BreachComment } from '@/types/model/ws/generatedModel.ts';
import { formatDateTime } from '@/core/utils/dateFormats.ts';
import { sortByDesc } from '@/core/utils/sortBy.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { CommentsForm } from '@/web/components/guardian/CommentsForm.tsx';

interface CommentPanelProps {
  alertId?: number;
  breachId?: number;
}

export function CommentsPanel({ alertId, breachId }: CommentPanelProps) {
  const commentListRef = useRef<HTMLElement>(null);
  const breach = useAppSelector(state => mainSlice.selectors.breach(state, alertId, breachId));
  const comments = (breach?.comments ?? []).toSorted(sortByDesc(b => b.instant));

  if (alertId === undefined || breachId === undefined) {
    return null;
  }

  function resetScroll() {
    if (commentListRef.current) {
      commentListRef.current.scrollTop = 0;
    }
  }

  const isClosed = comments.some(comment => comment.closingComment);

  return (
    <aside className="px-24px py-16px d-flex flex-column flex-grow-1 h-100">
      <h3 className="h5 mb-24px">Comments</h3>
      <section className="d-flex flex-column flex-grow-1">
        <CommentsList ref={commentListRef} comments={comments} />

        <CommentsForm
          alertId={alertId}
          breachId={breachId}
          onCommentPosted={resetScroll}
          breachHasClosingComment={isClosed}
        />
      </section>
    </aside>
  );
}

interface CommentsListProps {
  comments: BreachComment[];
}

const CommentsList = forwardRef<HTMLElement, CommentsListProps>(({ comments }, ref) => {
  if (comments.length === 0) {
    return <div className="flex-grow-1">No comments found.</div>;
  }

  return (
    // the flex-basis here enables the overflow to work properly
    <article
      ref={ref}
      className="list-group overflow-y-auto flex-grow-1 gap-12px"
      style={{ flexBasis: 0 }}
    >
      {comments.map((comment, index) => {
        const isLast = index === comments.length - 1;
        return (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            className={`d-flex flex-column gap-8px pb-12px border-opacity-40 ${!isLast ? 'border-bottom' : ''}`}
          >
            <div className="flex-between align-items-start">
              <div>
                <p className="fs-12 fw-semibold">{comment.userName}</p>
                <p className="fs-12 text-secondary">{formatDateTime(comment.instant)}</p>
              </div>
              {comment.closingComment && (
                <span className="badge badge-discreet-primary rounded-pill">Closing</span>
              )}
            </div>
            <p>{comment.comment}</p>
          </div>
        );
      })}
    </article>
  );
});
