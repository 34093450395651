import { useEffect, useState } from 'react';
import type { CustomHeaderProps } from '@ag-grid-community/react';
import { Checkbox } from '@sgme/ui';

import type { Breach } from '@/types/model/ws/generatedModel.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { toggleAllBreachesThunk, uiSlice } from '@/store/slices/uiSlice.ts';
import type { BreachContext } from '@/web/components/guardian/getBreachDetailsCellRenderer.tsx';
import { addAppListener } from '@/store/listenerMiddleware.ts';

export function BreachHeaderCheckbox({ context }: CustomHeaderProps<Breach, BreachContext>) {
  const dispatch = useAppDispatch();
  const alertId = context.alert.id;

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    return dispatch(
      addAppListener({
        predicate: (_, currentState, originalState) => {
          const countBreachesSelected = uiSlice.selectors.countBreachesSelected;
          const previouslySelected = countBreachesSelected(originalState, alertId);
          const currentlySelected = countBreachesSelected(currentState, alertId);
          return previouslySelected > 0 && currentlySelected === 0;
        },
        effect: () => {
          setSelected(false);
        },
      }),
    );
  }, [alertId, dispatch]);

  function handleCheckBoxChange(selected: boolean) {
    dispatch(toggleAllBreachesThunk(alertId, selected));
    setSelected(selected);
  }

  const hasPermission = useAppSelector(state =>
    apiSlice.selectors.userHasPermission(state, 'WRITE'),
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <Checkbox
      className="grid-checkbox"
      checked={selected}
      onCheckBoxChange={handleCheckBoxChange}
    />
  );
}
