import type { SgBootstrapTheme, SgBootstrapThemeExtended } from '@sgwt/sgwt-widgets-react';

declare global {
  interface Window {
    SGWTWidgetConfiguration: {
      context?: {
        getSgDesignSystemTheme(): SgBootstrapTheme;
      };
    };
  }
}

const sgbsVersion = import.meta.env.VITE_SGBS_VERSION;
const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${sgbsVersion}`;

function sgBoostrapUrls(variation: SgBootstrapTheme) {
  return {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${variation}.min.css`,
    icons: `${cdnUrl}/icons/index.min.css`,
  };
}

export function initSgBootstrap() {
  const theme = window.SGWTWidgetConfiguration.context?.getSgDesignSystemTheme();
  updateTheme(theme ?? 'system');
}

export function updateTheme(theme: SgBootstrapThemeExtended): void {
  updateThemeUrls(getVariation(theme));
}

function getVariation(theme: SgBootstrapThemeExtended | undefined): SgBootstrapTheme {
  switch (theme) {
    case 'standard':
    case 'dark':
      return theme;
    default: {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return prefersDark ? 'dark' : 'standard';
    }
  }
}

function updateThemeUrls(theme: SgBootstrapTheme): void {
  for (const [id, url] of Object.entries(sgBoostrapUrls(theme))) {
    appendUniqueLinkToDocument(id, url);
  }
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
  const newLinkElement = document.createElement('link');
  newLinkElement.rel = 'stylesheet';
  newLinkElement.id = id;
  newLinkElement.href = url;
  if (linkElement !== null) {
    newLinkElement.onload = () => {
      document.head.removeChild(linkElement);
    };
  }
  document.head.appendChild(newLinkElement);
}
