import type { JSX, PropsWithChildren } from 'react';

import { Modal, type ModalProps } from '@/web/components/bootstrap/modal/DialogModal.tsx';
import { DialogPortal } from '@/web/components/bootstrap/modal/DialogPortal.tsx';

export interface StandaloneModalProps extends ModalProps {
  show: boolean;
  // TODO
  // backdrop: boolean;
}

export function StandaloneModal({
  show,
  // backdrop = true,
  onClose,
  ...props
}: PropsWithChildren<StandaloneModalProps>): JSX.Element | null {
  return (
    <DialogPortal open={show} onClose={onClose}>
      <Modal onClose={onClose} {...props} />
    </DialogPortal>
  );
}
