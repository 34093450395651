import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { isRejected } from '@reduxjs/toolkit';
import { Checkbox } from '@sgme/ui';

import type { Breach } from '@/types/model/ws/generatedModel.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { uiSlice } from '@/store/slices/uiSlice.ts';
import { logger } from '@/web/logger.ts';
import { startAppListening } from '@/store/listenerMiddleware.ts';

export function BreachCheckbox({ data }: CustomCellRendererProps<Breach>) {
  const dispatch = useAppDispatch();
  const row = data!;
  const alertId = row.alertId;

  const breachClosed = row.comments.some(comment => comment.closingComment);
  const breachId = { alertId: alertId, breachId: row.id };

  const isBreachSelected = useAppSelector(state =>
    uiSlice.selectors.breachSelected(state, breachId),
  );

  function handleCheckBoxChange(selected: boolean) {
    dispatch(uiSlice.actions.toggleBreach({ breachId, selected }));
  }

  const hasPermission = useAppSelector(state =>
    apiSlice.selectors.userHasPermission(state, 'WRITE'),
  );

  if (!hasPermission || breachClosed) {
    return null;
  }

  return (
    <Checkbox
      className="grid-checkbox"
      checked={isBreachSelected}
      onCheckBoxChange={handleCheckBoxChange}
    />
  );
}
