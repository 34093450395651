import { setupSGWTConnect } from '@sgwt/connect-core';

import { getConfig } from '@/core/config/config.ts';

export const sgwtConnect = setupSGWTConnect(getConfig().sgconnect);

export function getAuthorizationHeader(): string | null {
  return sgwtConnect.getAuthorizationHeader();
}

export function getAuthorizationToken(): string | undefined {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader == null) {
    return undefined;
  }
  return authorizationHeader.replace('Bearer ', '');
}
