// https://github.com/sindresorhus/sort-on

export type Property<T> = ((element: T) => unknown) | Array<(element: T) => unknown>;

export type Options = {
  /**
   One or more locales to use when sorting strings.

   Should be a locale string or array of locale strings that contain one or more language or locale tags.

   If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale.

   If you omit this parameter, the default locale of the JavaScript runtime is used.

   This parameter must conform to BCP 47 standards. See {@link Intl.Collator} for more details.
   */
  readonly locales?: string | readonly string[];

  /**
   Comparison options.

   See {@link Intl.Collator} for more details.
   */
  readonly localeOptions?: Intl.CollatorOptions;

  readonly isDescending?: boolean;
};

export function sortByDesc<T>(
  property: Property<T>,
  options: Options = {},
): (a: T, b: T) => number {
  return sortBy(property, { ...options, isDescending: true });
}

export function sortBy<T>(
  property: Property<T>,
  { locales, localeOptions, isDescending = false }: Options = {},
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    let returnValue = 0;

    [property].flat().some(element => {
      const x = element(a);
      const y = element(b);

      if (x === y) {
        returnValue = 0;
        return false;
      }

      if (y !== 0 && !y) {
        returnValue = isDescending ? 1 : -1;
        return true;
      }

      if (x !== 0 && !x) {
        returnValue = isDescending ? -1 : 1;
        return true;
      }

      if (typeof x === 'string' && typeof y === 'string') {
        returnValue = isDescending
          ? y.localeCompare(x, locales, localeOptions)
          : x.localeCompare(y, locales, localeOptions);
        return returnValue !== 0;
      }

      if (isDescending) {
        returnValue = x < y ? 1 : -1;
      } else {
        returnValue = x < y ? -1 : 1;
      }

      return true;
    });

    return returnValue;
  };
}
