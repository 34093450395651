import { useCallback } from 'react';
import { ToastNotification } from '@sgme/ui';
import { Link } from '@tanstack/react-router';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import type { IntlMessage } from '@/types/intl';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { uiSlice, type IntlMessageOrString } from '@/store/slices/uiSlice.ts';

export function Toasts(): JSX.Element {
  const toastMessages = useAppSelector(state => state.ui.toastMessages);
  const dispatch = useAppDispatch();

  const onClose = useCallback(
    (messageId: string) => {
      dispatch(uiSlice.actions.deleteToast(messageId));
    },
    [dispatch],
  );

  return (
    <div style={{ position: 'absolute', top: 70, right: 20, zIndex: 100 }}>
      {toastMessages.map(({ body, id, link, severity, time, title }) => (
        <ToastNotification
          key={id}
          header={formatMessage(title)}
          content={
            <>
              {formatMessage(body)}

              {link && (
                <p>
                  <Link
                    to="/"
                    search={search => {
                      const { alertId, breachId } = link;
                      return { ...search, alertId, breachId };
                    }}
                  >
                    Navigate to breach
                  </Link>
                </p>
              )}
            </>
          }
          color={severity === 'error' ? 'danger' : 'primary'}
          footer={
            <div className="text-secondary fw-medium w-100 text-center">
              {format(parseISO(time), 'dd LLL yyyy - HH:mm')}
            </div>
          }
          onClose={() => onClose(id)}
        />
      ))}
    </div>
  );
}

function formatMessage(message: IntlMessageOrString | undefined) {
  if (message === undefined) {
    return undefined;
  }

  return isIntlMessage(message) ? (
    <FormattedMessage id={message.id} values={message.values} />
  ) : (
    message
  );
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function isIntlMessage(message: any): message is IntlMessage {
  return typeof message === 'object' && 'id' in message;
}
