import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
// import '@ag-grid-community/styles/ag-theme-quartz.css';
import './ag-theme.css';

export function initAgGrid() {
  ModuleRegistry.registerModules([MenuModule, CsvExportModule, ExcelExportModule, ClipboardModule]);

  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-056699}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Socit_Gnrale_S.A.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_December_2025}____[v3]_[01]_MTc2NzEzOTIwMDAwMA==376ef58ca7f47da3e33df87579ab0585',
  );
}
