import type { ICellRendererParams } from '@ag-grid-community/core';

import type { AlertStatus, BreachStatus } from '@/types/model/ws/generatedModel.ts';

export function StatusRenderer({
  value,
}: ICellRendererParams<unknown, AlertStatus | BreachStatus>) {
  switch (value) {
    case 'CLOSE':
    case 'STANDBY':
      return <div className="badge badge-sm rounded-pill badge-discreet-light">{value}</div>;
    case 'OPEN':
      return <div className="badge badge-sm rounded-pill badge-discreet-danger">{value}</div>;
    default:
      return <div className="badge badge-sm rounded-pill badge-discreet-light">{value}</div>;
  }
}
