import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { CompanyDto, MarketDto } from '@/types/model/ws/generatedModel.ts';
import { sortBy } from '@/core/utils/sortBy.ts';
import { fetchCompanies, fetchCurrentUser, fetchMarkets } from '@/store/async-thunks/api-thunks.ts';

// See https://iam-hom.sgmarkets.com/resources/b84698b6-47e1-461a-8c95-2a180e3c30ca#permissions
export type UserPermission = 'READ' | 'WRITE';

export interface CurrentUser {
  permissions: UserPermission[];
  email: string;
  name: string;
}

export interface ApiState {
  companies: CompanyDto[];
  markets: MarketDto[];
  user: CurrentUser;
}

const initialState: ApiState = {
  companies: [],
  markets: [],
  // @ts-ignore
  user: undefined,
};

const userHasPermission: (state: ApiState, permission: UserPermission) => boolean = createSelector(
  [(state: ApiState) => state.user, (_, permission: UserPermission) => permission],
  (user, permission) => {
    return user.permissions.includes(permission);
  },
);

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  selectors: {
    companies: state => state.companies,
    markets: state => state.markets,
    user: state => state.user,
    userHasPermission,
  },
  extraReducers: builder => {
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.companies = action.payload.toSorted(sortBy(c => c.bdrId));
    });
    builder.addCase(fetchMarkets.fulfilled, (state, action) => {
      state.markets = action.payload.toSorted(sortBy(m => m.id));
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});
