import { useRef, useState } from 'react';
import { Button } from '@sgme/ui';

import { DialogModal } from '@/web/components/bootstrap/modal/DialogModal.tsx';
import { StandaloneModal } from '@/web/components/bootstrap/modal/StandaloneModal.tsx';

export function PermissionModal() {
  const defaultShow = Notification.permission !== 'granted';
  const [show, setShow] = useState(defaultShow);

  function handleAuthorize() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        setShow(false);
      }
    });
  }

  function closeModal() {
    setShow(false);
  }

  return (
    <StandaloneModal
      show={show}
      canClickAway={false}
      header="Activate desktop notifications"
      onClose={closeModal}
      footer={
        <>
          <button type="button" onClick={closeModal} className="btn btn-lg btn-flat-secondary">
            Cancel
          </button>
          <button type="button" onClick={handleAuthorize} className="btn btn-lg btn-primary">
            Activate notifications
          </button>
        </>
      }
    >
      Activate notifications to get Windows desktop notifications for alerts. If you cancel this
      then you will not get notifications.
    </StandaloneModal>
  );
}
