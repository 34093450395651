import { useRef, useState, type FC, type PropsWithChildren } from 'react';
import { createSafeContext } from '@sgme/react';

import { DialogPortal } from '@/web/components/bootstrap/modal/DialogPortal.tsx';

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type ModalProps = {};
type ModalType = FC<ModalProps>;

interface ModalInternalApi {
  showModal(modal: ModalType): void;
  closeModal(): void;
}

interface ModalApi {
  showModal(): void;
  closeModal(): void;
}

const [ModalProviderInternal, useModalProvider] = createSafeContext<ModalInternalApi>('modal');

export function ModalProvider({ children }: PropsWithChildren) {
  const [CurrentModal, setCurrentModal] = useState<ModalType | undefined>();
  const dialogRef = useRef<HTMLDialogElement>(null);

  const modalApi: ModalInternalApi = {
    showModal(modal) {
      setCurrentModal(() => modal);
      dialogRef.current?.showModal();
    },
    closeModal() {
      dialogRef.current?.close();
    },
  };

  function handleClose() {
    modalApi.closeModal();
  }

  return (
    <ModalProviderInternal value={modalApi}>
      <DialogPortal ref={dialogRef} open={CurrentModal !== undefined} onClose={handleClose}>
        {CurrentModal !== undefined && <CurrentModal />}
      </DialogPortal>
      {children}
    </ModalProviderInternal>
  );
}

export function useModal(modal?: ModalType): ModalApi {
  const modalApi = useModalProvider();
  return {
    showModal() {
      if (modal === undefined) {
        throw new Error('Modal should be defined');
      }
      modalApi.showModal(modal);
    },
    closeModal() {
      modalApi.closeModal();
    },
  };
}
