import { useState } from 'react';
import { Button, RadioButtonGroup } from '@sgme/ui';

import { DialogModal } from '@/web/components/bootstrap/modal/DialogModal.tsx';
import { useModal } from '@/web/components/bootstrap/modal/ModalContext.tsx';

type SnoozeModalProps = {
  onSnooze: (durationMinutes: number, snoozed: boolean) => void;
};

export function SnoozeModal({ onSnooze }: SnoozeModalProps) {
  const modalApi = useModal();
  const [snoozeTimeMinutes, setSnoozeTimeMinutes] = useState<number>(30);
  return (
    <DialogModal
      header="Snooze breach"
      footer={
        <>
          <Button onClick={() => modalApi.closeModal()}>Cancel</Button>
          <Button
            purpose="primary"
            onClick={() => {
              onSnooze(snoozeTimeMinutes, true);
              modalApi.closeModal();
            }}
          >
            Snooze
          </Button>
        </>
      }
    >
      <div>
        <label className="mb-1">Select snooze duration:</label>
        <RadioButtonGroup
          selectedValue={snoozeTimeMinutes}
          onChange={setSnoozeTimeMinutes}
          options={[
            { value: 30, label: '30 mins' },
            { value: 60, label: '1 hour' },
            { value: 120, label: '2 hours' },
            { value: 240, label: '4 hours' },
          ]}
        />
      </div>
    </DialogModal>
  );
}
