import { addSeconds, formatISO } from 'date-fns';

import type { Alert, Breach, EventDto } from '@/types/model/ws/generatedModel.ts';
import type { GuiNotification } from '@/types/model/ws/model.ts';

interface AlertDb extends Alert {
  breaches?: BreachDb[];
  updates?: Partial<AlertDb>[];
}

interface BreachDb extends Breach {
  events?: EventDto[];
  updates?: Partial<BreachDb>[];
}

interface GuiNotificationDb extends GuiNotification {
  delay?: number;
}

export interface AlertDbData {
  alerts: AlertDb[];
  guiNotifications: GuiNotificationDb[];
}

let alertIdCounter = 1000;
let breachIdCounter = 1000;

export const alertDb: AlertDbData = {
  alerts: [
    makeAlert({
      id: 1003,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_MINUTE',
        assetType: 'FUTURE',
        product: 'prod',
      },
      date: '2024-04-19',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
      updates: [{ status: 'CLOSE' }],
      breachCount: 3,
    }),
    makeAlert({
      id: 1013,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_MINUTE',
      },
      date: '2024-04-22',
      status: 'STANDBY',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      totalBreachCount: 1,
      openBreachCount: 1,
      worstSeverity: 'HIGH',
      breachCount: 4,
    }),
    makeAlert({
      id: 1016,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_MINUTE',
      },
      date: '2024-04-23',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
      breachCount: 2,
    }),
    makeAlert({
      id: 1019,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_MINUTE',
      },
      date: '2024-04-24',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
      breachCount: 3,
    }),
    makeAlert({
      id: 1002,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_SECOND',
      },
      date: '2024-04-19',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
    }),
    makeAlert({
      id: 1012,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_SECOND',
      },
      date: '2024-04-22',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
    }),
    makeAlert({
      id: 1015,
      key: {
        marketId: 1000,
        bdrId: 7607,
        controlType: 'FREQUENCY_SECOND',
      },
      date: '2024-04-23',
      status: 'OPEN',
      limitThreshold: 'limit: 500',
      currentReached: {
        percent: 90,
      },
      maxReached: {
        percent: 90,
      },
      worstSeverity: 'HIGH',
      breachCount: 7,
    }),
  ],
  guiNotifications: [
    {
      type: 'TOAST',
      title: 'New breach',
      // sound: 'SOUND1',
      delay: 3000,
      alertId: 1019,
      breachId: 1003,
    },
    {
      type: 'DESKTOP_NOTIFICATION',
      title: 'New comment',
      body: 'New comment on breach',
      delay: 1000,
      alertId: 1002,
      breachId: 1012,
    },
    // {
    //   type: 'DESKTOP_NOTIFICATION',
    //   title: 'Test notification 1',
    //   body: '(☞ﾟヮﾟ)☞',
    //   delay: 1000,
    //   alertId: 1019,
    //   breachId: 1003,
    // },
    // {
    //   type: 'DESKTOP_NOTIFICATION',
    //   title: 'Test notification 2',
    //   body: '☜(ﾟヮﾟ☜)',
    //   delay: 1200,
    //   alertId: 1019,
    //   breachId: 1003,
    // },
    // {
    //   type: 'DESKTOP_NOTIFICATION',
    //   title: 'Test notification 3',
    //   body: '(☞ﾟヮﾟ)☞',
    //   delay: 1400,
    //   alertId: 1019,
    //   breachId: 1003,
    // },
  ],
};

function makeAlert(alertData: Partial<AlertDb> & { breachCount?: number }): AlertDb {
  const id = alertData.id ?? alertIdCounter++;
  const alert: AlertDb = {
    id,
    key: {
      marketId: 1000,
      bdrId: 7607,
      controlType: 'FREQUENCY_MINUTE',
      assetType: 'FUTURE',
      product: 'prod',
    },
    date: '2024-04-19',
    status: 'OPEN',
    openBreachCount: 1,
    limitThreshold: 'limit: 500',
    uncommentedBreachCount: 0,
    totalBreachCount: 1,
    currentReached: {
      percent: 90,
    },
    maxReached: {
      percent: 90,
    },
    worstSeverity: 'HIGH',
    ...alertData,
  };
  const breaches =
    alertData.breaches ?? getDefaultBreaches({ alertId: id, numBreaches: alertData.breachCount });
  alert.uncommentedBreachCount = breaches.filter(b => b.comments.length === 0).length;
  alert.totalBreachCount = breaches.length;
  alert.openBreachCount = breaches.filter(b => b.status === 'OPEN').length;
  alert.breaches = breaches;
  return alert;
}

function getDefaultBreaches({
  alertId,
  numBreaches = 1,
}: {
  alertId: number;
  numBreaches?: number;
}): BreachDb[] {
  return Array.from({ length: numBreaches }, (_, numBreach) => {
    const breachId = breachIdCounter++;
    return {
      id: breachId,
      alertId,
      status: 'OPEN',
      startInstant: '2024-04-19T12:48:16.798674Z',
      eventCount: 0,
      worstSeverity: 'HIGH',
      breachType: 'Client - Overshoot',
      currentReached: { percent: 50 },
      maxReached: { percent: 50 },
      comments: [
        {
          comment: 'False alarm',
          instant: '2024-04-19T13:00:00.000000Z',
          userName: 'geoffroy.warin@sgcib.com',
          closingComment: alertId % 2 === 0,
        },
      ],
      updates: [
        ...Array.of(2, 3, 4).map(sequence => {
          const breach: Partial<BreachDb> = {
            eventCount: sequence,
            events: [
              {
                breachId,
                sequence,
                instant: formatISO(addSeconds('2024-04-19T12:48:16.798674310Z', sequence)),
                severity: 'LOW',
                reached: { percent: 100 + 10 * sequence },
                details: { side: 'Sell', price: 100, symbol: 'MSFT', quantity: 100 },
              },
            ],
          };
          return breach;
        }),
      ],
      events: [
        {
          breachId,
          sequence: 1,
          instant: '2024-04-19T12:48:16.798674310Z',
          severity: 'LOW',
          reached: { percent: 100 },
          details: { side: 'Sell', price: 100, symbol: 'MSFT', quantity: 100 },
        },
      ],
    };
  });
}
