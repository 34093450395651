import { Component, type ErrorInfo, type PropsWithChildren } from 'react';

import { DefaultErrorRenderer } from '@/web/components/common/DefaultErrorRenderer.tsx';
import { logger } from '@/web/logger.ts';

interface CatchProps {
  errorRenderer?: (props: { error: Error }) => JSX.Element;
}

export class Catch extends Component<PropsWithChildren<CatchProps>, { error: Error | undefined }> {
  constructor(props: CatchProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.logError(
      'Error boundary {error_s} {errorInfo_s}',
      JSON.stringify(error),
      JSON.stringify(errorInfo),
    );
  }

  render() {
    if (this.state.error) {
      if (this.props.errorRenderer !== undefined) {
        return this.props.errorRenderer({ error: this.state.error });
      }
      return <DefaultErrorRenderer error={this.state.error} />;
    }
    return this.props.children;
  }
}
