import type { ReactNode } from 'react';

export interface HeaderProps {
  onClose: () => void;
  closeButton: ReactNode;
}

export function ModalHeader({
  header,
  onClose,
}: {
  header: string | undefined | ((props: HeaderProps) => ReactNode);
  onClose: () => void;
}) {
  if (header === undefined) {
    return null;
  }

  if (typeof header === 'string') {
    return (
      <>
        <h3 className="modal-title">{header}</h3>
        <button onClick={onClose} type="button" className="btn-close" aria-label="Close" />
      </>
    );
  }

  return header({
    onClose,
    closeButton: (
      <button onClick={onClose} type="button" className="btn-close" aria-label="Close" />
    ),
  });
}
