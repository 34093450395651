import { EventEmitter } from 'events';
import { addMinutes, differenceInSeconds, isAfter, isEqual, isWithinInterval } from 'date-fns';
import { coercer } from 'ofcoerce';

import { coerceRecord } from '@/core/utils/coerceRecord.ts';

export interface Snooze {
  date: string;
  durationMinutes: number;
}

const snoozesCoercer = coerceRecord(
  coercer<Snooze>(() => ({
    date: String,
    durationMinutes: Number,
  })),
);

const storageKey = 'afm_snoozes';

export class SnoozeManager {
  public eventEmitter = new EventEmitter<{ snoozeChanged: [] }>();
  private readonly currentSnoozes: Record<string, Snooze>;
  private timeout: NodeJS.Timeout | undefined;

  static instance(): SnoozeManager {
    return snoozeManager;
  }

  constructor() {
    this.currentSnoozes = this.loadSnoozes();
    this.refreshAfterNextExpiration();
  }

  setSnooze(
    alertId: number,
    breachId: number,
    date: string,
    durationMinutes: number,
    snooze: boolean,
  ) {
    const id = this.getId(alertId, breachId);
    const value: Snooze = {
      date,
      durationMinutes,
    };
    if (snooze) {
      this.currentSnoozes[id] = value;
    } else {
      delete this.currentSnoozes[id];
    }

    localStorage.setItem(storageKey, JSON.stringify(this.currentSnoozes));
    this.refreshAfterNextExpiration();
    this.eventEmitter.emit('snoozeChanged');
  }

  getSecondsToNextExpiration(now: string) {
    const sortedExpirations = Object.values(this.currentSnoozes)
      .map(this.getSnoozeExpiration)
      .toSorted();
    const nextExpiration = sortedExpirations.find(exp => isAfter(exp, now) || isEqual(exp, now));
    if (nextExpiration === undefined) {
      return undefined;
    }

    return differenceInSeconds(nextExpiration, now);
  }

  isSnoozed(alertId: number | undefined, breachId: number | undefined, now: string): boolean {
    if (alertId === undefined || breachId === undefined) {
      return false;
    }

    const snooze = this.currentSnoozes[this.getId(alertId, breachId)];
    if (snooze === undefined) {
      return false;
    }
    const expiration = this.getSnoozeExpiration(snooze);
    return isWithinInterval(now, { start: snooze.date, end: expiration });
  }

  private loadSnoozes(): Record<string, Snooze> {
    const currentSnoozes = JSON.parse(localStorage.getItem(storageKey) ?? '{}');
    return snoozesCoercer(currentSnoozes);
  }

  private refreshAfterNextExpiration() {
    const now = new Date().toISOString();
    const secondsToNextExpiration = this.getSecondsToNextExpiration(now);
    if (secondsToNextExpiration === undefined) {
      return;
    }

    console.log(`Refresh in ${secondsToNextExpiration}s`);
    clearTimeout(this.timeout);
    // add delta because we want the snooze to be expired when we refresh
    const delta = 100;
    this.timeout = setTimeout(
      () => {
        console.log('refresh');
        this.eventEmitter.emit('snoozeChanged');
      },
      secondsToNextExpiration * 1000 + delta,
    );
  }

  private getSnoozeExpiration(snooze: Snooze) {
    return addMinutes(snooze.date, snooze.durationMinutes);
  }

  private getId(alertId: number, breachId: number) {
    return `${alertId}-${breachId}`;
  }
}

const snoozeManager: SnoozeManager = new SnoozeManager();
