import { HelpCenter } from '@/web/components/sgwt/HelpCenter.tsx';
import { MiniFooter } from '@/web/components/sgwt/MiniFooter.tsx';

export function Footer() {
  return (
    <footer>
      <HelpCenter />
      <MiniFooter />
    </footer>
  );
}
