import { StrictMode } from 'react';
import { isFulfilled } from '@reduxjs/toolkit';
import type { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { SgwtWidgetContextProvider, type SgwtConnectHTMLElement } from '@sgwt/sgwt-widgets-react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@/store/store.ts';
import { fetchCurrentUser } from '@/store/async-thunks/api-thunks.ts';
import { initAgGrid } from '@/web/ag-grid/setupAgGrid.ts';
import { App } from '@/web/components/App.tsx';
import { logger } from '@/web/logger.ts';
import { sgwtConnect } from '@/web/sgwt/sgwtConnect.ts';

initAgGrid();
setupSgwtConnectWidget(sgwtConnect);

if (sgwtConnect.isAuthorized()) {
  renderApp();
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError !== null) {
    renderError(authorizationError);
  } else {
    sgwtConnect.requestAuthorization();
  }
}

async function renderApp() {
  const rootElement = document.getElementById('root')!;
  const root = createRoot(rootElement);

  const action = await store.dispatch(fetchCurrentUser());
  if (!isFulfilled(action)) {
    redirectToRequestAccessPage(`${action.error.message} (online=${navigator.onLine})`);
    return;
  }

  root.render(
    <StrictMode>
      <Provider store={store}>
        <SgwtWidgetContextProvider infrastructure="azure">
          <App />
        </SgwtWidgetContextProvider>
      </Provider>
    </StrictMode>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
        widget.setSgwtConnectInstance(sgwtConnect);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}

function redirectToRequestAccessPage(errorMessage: string | undefined) {
  logger.logInformation('Redirecting to request access page: {error_s}', errorMessage);
  window.location.replace(window.sgmeConfiguration.accessRequestUrl + window.location.href);
}
