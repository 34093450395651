import { Suspense, type PropsWithChildren } from 'react';
import { Loader } from '@sgme/ui';

import { Catch } from '@/web/components/common/Catch.tsx';

interface SuspenseWrapperProps {
  errorRenderer?: (props: { error: Error }) => JSX.Element;
}

export const SuspenseWrapper = ({
  errorRenderer,
  children,
}: PropsWithChildren<SuspenseWrapperProps>) => {
  return (
    <Catch errorRenderer={errorRenderer}>
      <Suspense fallback={<Loader fullScreen />}>{children}</Suspense>
    </Catch>
  );
};
