import { SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { useIntl } from 'react-intl';

import { updateTheme } from '@/web/theme.ts';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();

  return (
    <SgwtAccountCenter
      availableLanguages={['fr', 'en']}
      authentication="sg-connect"
      mode="sg-markets"
      language={intl.locale}
      producerCode={import.meta.env.VITE_ACCOUNT_CENTER_PRODUCER_CODE}
      themeSwitcher
      onThemeChanged={e => {
        const theme = e.themeChosen;
        updateTheme(theme);
      }}
    />
  );
}
