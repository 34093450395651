import type { Alert, Breach, EventDto } from '@/types/model/ws/generatedModel.ts';
import type { GuiNotification } from '@/types/model/ws/model.ts';

type WsOperation = 'AUTHENTICATE_GUI' | 'SUBSCRIBE' | 'UNSUBSCRIBE';
export type ResponseType = SuccessWsResponse | ErrorWsResponse;

export interface WsResponse {
  type: 'RESPONSE';
  payload: ResponseType;
}

export type NotificationType =
  | AlertImagePayload
  | BreachImagePayload
  | EventImagePayload
  | AlertUpdatePayload
  | BreachUpdatePayload
  | EventUpdatePayload
  | GuiNotificationUpdatePayload;

export interface WsNotification<T extends NotificationType = NotificationType> {
  type: 'NOTIFICATION';
  payload: T;
}

export function isBreachNotification(payload: NotificationType): payload is BreachImagePayload {
  return payload.type === 'IMAGE' && payload.subject === 'BREACH';
}

export function isAlertNotification(payload: NotificationType): payload is AlertImagePayload {
  return payload.type === 'IMAGE' && payload.subject === 'ALERT';
}

export function isEventNotification(payload: NotificationType): payload is EventImagePayload {
  return payload.type === 'IMAGE' && payload.subject === 'EVENT';
}

export interface SuccessWsResponse {
  id: number;
  operation: WsOperation;
  success: true;
}

interface ErrorWsResponse {
  id: number;
  operation: WsOperation;
  success: false;
  errorMessage: string;
}

export interface AlertImagePayload {
  subject: 'ALERT';
  type: 'IMAGE';
  payload: {
    alerts: Alert[];
  };
}

export interface AlertUpdatePayload {
  subject: 'ALERT';
  type: 'UPDATE' | 'ADD';
  payload: Alert;
}

export interface BreachImagePayload {
  subject: 'BREACH';
  type: 'IMAGE';
  payload: {
    breaches: Breach[];
  };
}

export interface BreachUpdatePayload {
  subject: 'BREACH';
  type: 'UPDATE' | 'ADD';
  payload: Breach;
}

export interface EventImagePayload {
  subject: 'EVENT';
  type: 'IMAGE';
  payload: {
    events: EventDto[];
  };
}

export interface EventUpdatePayload {
  subject: 'EVENT';
  type: 'UPDATE' | 'ADD';
  payload: EventDto;
}

export interface GuiNotificationUpdatePayload {
  subject: 'GUI_NOTIFICATION';
  type: 'ADD';
  payload: GuiNotification;
}
