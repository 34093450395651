import { useState } from 'react';
import { Allotment } from 'allotment';
import clsx from 'clsx';

import { SuspenseWrapper } from '@/web/components/common/SuspenseWrapper.tsx';
import { BottomPanel } from '@/web/components/layout/BottomPanel.tsx';
import { TopPanel } from '@/web/components/layout/TopPanel.tsx';

export interface ExpandedDetails {
  expandedAlertId?: number;
  expandedBreachId?: number;
}

export interface MainPageProps extends ExpandedDetails {}

export function MainPage(props: MainPageProps) {
  const [dragging, setDragging] = useState(false);

  function handleDragStart() {
    setDragging(true);
  }

  function handleDragEnd() {
    setDragging(false);
  }

  return (
    <SuspenseWrapper>
      <Allotment vertical={true} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Allotment.Pane className="d-flex flex-column" preferredSize={'70%'}>
          <TopPanel {...props} />
        </Allotment.Pane>
        <Allotment.Pane className="d-flex flex-column">
          <BottomPanel className={clsx({ dragging })} />
        </Allotment.Pane>
      </Allotment>
    </SuspenseWrapper>
  );
}
