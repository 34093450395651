import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import type { EventDto } from '@/types/model/ws/generatedModel.ts';
import { formatDateTime } from '@/core/utils/dateFormats.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { commonGridOptions } from '@/web/ag-grid/commonTable.ts';
import { SeverityRenderer } from '@/web/components/guardian/table/SeverityRenderer.tsx';

const columnDefs: ColDef<EventDto>[] = [
  { field: 'sequence', maxWidth: 145 },
  { field: 'instant', valueFormatter: ({ value }) => formatDateTime(value), maxWidth: 300 },
  {
    field: 'severity',
    cellRenderer: SeverityRenderer,
    filter: 'agSetColumnFilter',
    maxWidth: 200,
  },
  { field: 'reached.percent', maxWidth: 200 },
  {
    field: 'details',
    valueFormatter: ({ value }) => (typeof value === 'string' ? value : JSON.stringify(value)),
  },
];

export function EventsTable() {
  const events = useAppSelector(mainSlice.selectors.currentEvents);

  return (
    <AgGridReact<EventDto>
      {...commonGridOptions}
      modules={[
        ClientSideRowModelModule,
        StatusBarModule,
        RowGroupingModule,
        RangeSelectionModule,
        SideBarModule,
        ColumnsToolPanelModule,
        FiltersToolPanelModule,
        SetFilterModule,
      ]}
      className="grid-container ag-theme-alpine"
      containerStyle={{ height: undefined }}
      columnDefs={columnDefs}
      rowData={events}
      getRowId={params => params.data.sequence.toString()}
      sideBar={{
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
              suppressColumnExpandAll: true,
            },
          },
          'filters',
        ],
      }}
    />
  );
}
