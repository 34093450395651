import type { ReactNode } from 'react';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

import { getConfig } from '@/core/config/config.ts';

export function HelpCenter(): ReactNode {
  const { subject } = getConfig().helpCenter;
  return (
    <aside>
      <SgwtHelpCenter applicationId={import.meta.env.VITE_SPLASH_APP_ID} mailSubject={subject} />
    </aside>
  );
}
