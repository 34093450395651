export function DefaultErrorRenderer({ error }: { error: Error }): JSX.Element {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">{error.name}</h4>
      <div className="mt-2">
        {error.message !== undefined && <div>{`${error.message}`}</div>}
        {import.meta.env.DEV && error.cause !== undefined && (
          <div>{`${JSON.stringify(error.cause)}`}</div>
        )}
      </div>
    </div>
  );
}
