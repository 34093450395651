import { useRef, type JSX, type PropsWithChildren, type ReactNode } from 'react';
import type { Size } from '@sgme/ui';
import { useClickAway } from 'react-use';

import { useModal } from '@/web/components/bootstrap/modal/ModalContext.tsx';
import { ModalHeader, type HeaderProps } from '@/web/components/bootstrap/modal/ModalHeader.tsx';

type Color = 'primary' | 'info' | 'success' | 'warning' | 'danger';

export interface DialogModalProps {
  size?: Size;
  purpose?: 'default' | 'contextual';
  color?: Color;

  header: string | ((props: HeaderProps) => ReactNode);
  footer?: ReactNode;
}

export function DialogModal(props: PropsWithChildren<DialogModalProps>) {
  const modalApi = useModal();
  const handleClose = () => {
    modalApi.closeModal();
  };

  return <Modal {...props} onClose={handleClose} />;
}

export interface ModalProps extends DialogModalProps {
  onClose: () => void;
  canClickAway?: boolean;
}

export function Modal({
  children,
  header,
  purpose = 'default',
  color = 'primary',
  footer,
  size = 'md',
  onClose,
  canClickAway = true,
}: PropsWithChildren<ModalProps>): JSX.Element | null {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useClickAway(modalRef, () => {
    if (canClickAway) {
      onClose();
    }
  });

  return (
    <>
      <div className={`modal-dialog modal-${size}`} ref={modalRef}>
        <div className="modal-content shadow-max">
          <div className="modal-header">
            <ModalHeader header={header} onClose={onClose} />
          </div>
          <div className="modal-body">{children}</div>

          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </>
  );
}
