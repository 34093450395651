import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import type { ColDef, GridApi, RowClassParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { getRouteApi, useNavigate } from '@tanstack/react-router';

import type { Alert, CompanyDto, MarketDto } from '@/types/model/ws/generatedModel.ts';
import { formatDate } from '@/core/utils/dateFormats.ts';
import { useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { commonGridOptions } from '@/web/ag-grid/commonTable.ts';
import { getBreachDetailsCellRenderer } from '@/web/components/guardian/getBreachDetailsCellRenderer.tsx';
import { SeverityRenderer } from '@/web/components/guardian/table/SeverityRenderer.tsx';
import { StatusRenderer } from '@/web/components/guardian/table/StatusRenderer.tsx';
import type { ExpandedDetails } from '@/web/components/layout/MainPage.tsx';

function getColumnDefs(companies: CompanyDto[], markets: MarketDto[]): ColDef<Alert>[] {
  return [
    { field: 'id', cellRenderer: 'agGroupCellRenderer' },
    {
      field: 'key.bdrId',
      headerName: 'Company',
      valueFormatter: ({ value: bdrId }) => {
        return companies.find(c => c.bdrId === bdrId)?.name ?? bdrId;
      },
    },
    {
      field: 'key.bdrId',
      headerName: 'Company Id',
      hide: true,
    },
    {
      field: 'key.marketId',
      headerName: 'Market',
      valueFormatter: ({ value: marketId }) => {
        return markets.find(m => m.id === marketId)?.name ?? marketId;
      },
    },
    {
      field: 'key.marketId',
      headerName: 'Market Id',
      hide: true,
    },
    {
      field: 'key.product',
      headerName: 'Product Id',
      hide: true,
    },
    {
      field: 'key.assetType',
      headerName: 'Asset Type',
      hide: true,
    },
    { field: 'key.controlType', headerName: 'Control Type' },
    { field: 'date', valueFormatter: ({ value }) => formatDate(value) },
    { field: 'limitThreshold' },
    { field: 'totalBreachCount' },
    { field: 'uncommentedBreachCount' },
    { field: 'currentReached.percent' },
    { field: 'maxReached.percent' },
    {
      field: 'worstSeverity',
      cellRenderer: SeverityRenderer,
      filter: 'agSetColumnFilter',
    },
    {
      field: 'status',
      cellRenderer: StatusRenderer,
      filter: 'agSetColumnFilter',
    },
  ];
}

interface AlertTableProps extends ExpandedDetails {}

const routeApi = getRouteApi('/');

export function AlertsTable({ expandedAlertId, expandedBreachId }: AlertTableProps) {
  const { subscriptionManager } = routeApi.useLoaderData();
  const alerts = useAppSelector(mainSlice.selectors.alerts);
  const companies = useAppSelector(apiSlice.selectors.companies);
  const markets = useAppSelector(apiSlice.selectors.markets);

  const navigate = useNavigate();
  const gridApi = useRef<AgGridReact<Alert>>(null);

  useEffect(() => {
    if (gridApi.current?.api) {
      expandTables(gridApi.current.api, expandedAlertId, expandedBreachId);
    }
  }, [expandedAlertId, expandedBreachId]);

  const onBreachSelected = useCallback(
    async (alertId: number, breachId: number) => {
      await navigate({
        to: '/',
        search: search => ({ ...search, alertId, breachId }),
        state: prev => ({ ...prev, scroll: false }),
      });
    },
    [navigate],
  );

  const detailCellRendererParams = useMemo(() => {
    return getBreachDetailsCellRenderer(subscriptionManager, onBreachSelected);
  }, [subscriptionManager, onBreachSelected]);

  const columnDefs = useMemo(() => {
    return getColumnDefs(companies, markets);
  }, [companies, markets]);

  const getRowClass: (p: RowClassParams<Alert>) => string | string[] | undefined = useCallback(
    params => {
      const status = params.data?.status;
      if (status === 'CLOSE' || status === 'STANDBY') {
        return 'text-secondary';
      }
    },
    [],
  );

  return (
    <AgGridReact<Alert>
      ref={gridApi}
      modules={[
        ClientSideRowModelModule,
        StatusBarModule,
        RowGroupingModule,
        RangeSelectionModule,
        SideBarModule,
        ColumnsToolPanelModule,
        MasterDetailModule,
        FiltersToolPanelModule,
        SetFilterModule,
      ]}
      {...commonGridOptions}
      className="grid-container ag-theme-alpine"
      containerStyle={{ height: undefined }}
      columnDefs={columnDefs}
      rowData={alerts}
      masterDetail
      detailCellRendererParams={detailCellRendererParams}
      onFirstDataRendered={({ api }) => {
        expandTables(api, expandedAlertId, expandedBreachId);
      }}
      getRowClass={getRowClass}
      // detailRowAutoHeight
      // detailRowHeight={400}
      getRowId={params => params.data.id.toString()}
      getRowHeight={params => {
        const isDetailRow = params.node.detail;
        // for all rows that are not detail rows, return nothing
        if (!isDetailRow) {
          return undefined;
        }
        return clamp(
          detailHeight(3),
          detailHeight(5),
          detailHeight(params.data?.totalBreachCount ?? 0),
        );
      }}
      rowClass="bg-lvl1"
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
    />
  );
}

function expandTables(
  api: GridApi<Alert>,
  alertId: number | undefined,
  breachId: number | undefined,
) {
  if (alertId !== undefined) {
    const rowNode = api.getRowNode(alertId.toString());
    if (rowNode != null) {
      rowNode.setExpanded(true);
      if (globalThis.history.state?.scroll !== false) {
        api.ensureNodeVisible(rowNode, 'top');
      }
    }

    if (breachId !== undefined) {
      tryFocusBreach(api, alertId, breachId);
    }
  }
}

function tryFocusBreach(api: GridApi<Alert>, alertId: number, breachId: number, tryNum = 0): void {
  if (tryNum > 10) {
    console.warn('Could not focus breach', alertId, breachId);
    return;
  }

  setTimeout(() => {
    if (!focusBreach(api, alertId, breachId)) {
      tryFocusBreach(api, alertId, breachId, tryNum + 1);
    }
  }, tryNum * 10);
}

function focusBreach(api: GridApi<Alert>, alertId: number, breachId: number): boolean {
  const detailsApi = api.getDetailGridInfo(`detail_${alertId}`)?.api;
  if (detailsApi == null) {
    return false;
  }

  const breachRow = detailsApi?.getRowNode(breachId.toString());
  if (breachRow === undefined) {
    return false;
  }
  breachRow.setSelected(true, true);
  return true;
}

function clamp(min: number, max: number, value: number) {
  return Math.max(Math.min(max, value), min);
}

function detailHeight(rows: number) {
  // Header + padding = 110, each row = 42
  const padding = 23 * 2;
  const header = 32;
  const rowHeight = 22;
  return padding + header + rows * rowHeight;
}
