export function coerceRecord<V>(
  valueCoercer: (value: unknown) => V,
): (obj: unknown) => Record<string, V> {
  return obj => {
    if (obj == null || typeof obj !== 'object') {
      return {};
    }
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, valueCoercer(v)]));
  };
}
