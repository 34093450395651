import { isRejected, type UnknownAction } from '@reduxjs/toolkit';

import { uiSlice } from '@/store/slices/uiSlice.ts';
import { logger } from '@/web/logger.ts';
import { startAppListening } from '@/store/listenerMiddleware.ts';

export function startErrorActionListener() {
  startAppListening({
    predicate: (action, currentState, originalState) => {
      return isRejected(action);
    },
    effect: (action, api) => {
      const errorMessage = action.error.message ?? JSON.stringify(action.payload);
      logger.logError('Error on action: {error_s}', errorMessage);
      api.dispatch(
        uiSlice.actions.addToast({
          severity: 'error',
          body: errorMessage,
        }),
      );
    },
  });
}
