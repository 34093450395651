import { createFileRoute } from '@tanstack/react-router';
import { coercer } from 'ofcoerce';

import { websocketLoader } from '@/web/components/guardian/websocketLoader.tsx';
import { MainPage } from '@/web/components/layout/MainPage.tsx';

interface PageParams {
  alertId?: number;
  breachId?: number;
}

const pageParamCoercer = coercer<PageParams>($ => ({
  alertId: $.Optional(Number),
  breachId: $.Optional(Number),
}));

export const Route = createFileRoute('/')({
  loader: websocketLoader,
  shouldReload: false,
  validateSearch: (search: Record<string, unknown>): PageParams => {
    return pageParamCoercer(search);
  },
  component: () => {
    const { alertId, breachId } = Route.useSearch();
    return <MainPage expandedAlertId={alertId} expandedBreachId={breachId} />;
  },
});
