import ky from 'ky';

import { getConfig } from '@/core/config/config.ts';
import { logger } from '@/web/logger.ts';
import { getAuthorizationHeader } from '@/web/sgwt/sgwtConnect.ts';

export function setRequestHeaders(headers: Headers) {
  const authorizationHeader = getAuthorizationHeader();
  if (authorizationHeader) {
    headers.set('Authorization', authorizationHeader);
  }

  const searchParameters = new URLSearchParams(window.location.search);
  if (searchParameters.has('user')) {
    headers.set('X-Fake-Authentication', searchParameters.get('user')!);
  }
  if (searchParameters.has('permissions')) {
    headers.set('X-Fake-Iam-Permissions', searchParameters.get('permissions')!);
  }

  return headers;
}

export const webApi = ky.create({
  prefixUrl: getConfig().apiUrl,
  hooks: {
    beforeError: [
      error => {
        const { request, message } = error;
        error.message = `${request.method} ${urlPath(request.url)}: ${message}`;
        return error;
      },
    ],
    beforeRetry: [
      async ({ request, options, error, retryCount }) => {
        logger.logError(
          'Retrying request on {url_s} (retry {retry_n}) after error {error_s}',
          urlPath(request.url),
          retryCount,
          error.message,
        );
      },
    ],
    beforeRequest: [
      request => {
        setRequestHeaders(request.headers);
      },
    ],
  },
});

function urlPath(url: string): string {
  try {
    return new URL(url).pathname;
  } catch {
    return url;
  }
}
