import type { InputHTMLAttributes } from 'react';
import { isDefined } from '@sgme/fp';
import type { Size } from '@sgme/ui';
import { isStringNonEmpty } from '@sgme/ui/lib/utils/utilities';
import clsx from 'clsx';

export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  description?: string;

  size?: Size;
  outline?: boolean;

  validationState?: 'default' | 'edited' | 'valid' | 'warning' | 'invalid';
  validationText?: string;

  icon?: string;
  iconPosition?: 'start' | 'end';

  wrapperClassName?: string;
}

/**
 * Wrap on HTML input of type "text" in a SG Bootstrap 5 components.
 *
 * @example
 * <TextInput purpose="primary" size="lg" />
 */
export const TextInput = (props: TextInputProps) => {
  const {
    value,
    placeholder,
    description,
    size = 'md',
    outline: isOutline = false,
    validationState,
    validationText,
    icon,
    iconPosition = 'start',
    className,
    wrapperClassName,
    ...otherProps
  } = props;

  const inputClassName = clsx(
    ['form-control', size !== undefined ? `form-control-${size}` : undefined, className],
    {
      'is-edited': validationState === 'edited',
      'is-valid': validationState === 'valid',
      'is-warning': validationState === 'warning',
      'is-invalid': validationState === 'invalid',

      'form-control-outline': isOutline,
    },
  );

  return (
    <div className={clsx('form-group', wrapperClassName)}>
      {isDefined(icon) ? (
        <div className="input-group">
          <div className={`input-icon-${iconPosition}`}>
            <em className="icon">{icon}</em>
          </div>

          <input
            type="text"
            placeholder={placeholder}
            value={value}
            className={inputClassName}
            {...otherProps}
          />
        </div>
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          className={inputClassName}
          {...otherProps}
        />
      )}

      {validationState === 'edited' && (
        <span className="edited-feedback">
          <em className="icon">warning</em>
          {validationText}
        </span>
      )}

      {validationState === 'valid' && (
        <span className="valid-feedback">
          <em className="icon">done</em>
          {validationText}
        </span>
      )}

      {validationState === 'invalid' && (
        <span className="invalid-feedback">
          <em className="icon">close</em>
          {validationText}
        </span>
      )}

      {validationState === 'warning' && (
        <span className="warning-feedback">
          <em className="icon">warning</em>
          {validationText}
        </span>
      )}

      {isStringNonEmpty(description) && <span className="form-text">{description}</span>}
    </div>
  );
};
