import { forwardRef, type PropsWithChildren } from 'react';
import clsx from 'clsx';
import { createPortal } from 'react-dom';

interface DialogPortalProps {
  open: boolean;
  onClose: () => void;
}

export const DialogPortal = forwardRef<HTMLDialogElement, PropsWithChildren<DialogPortalProps>>(
  ({ open, onClose, children }, ref) => {
    return createPortal(
      <dialog
        ref={ref}
        aria-modal={true}
        role="alertdialog"
        open={open}
        className={clsx('modal')}
        onClose={onClose}
        style={{ backgroundColor: 'unset', color: 'unset', border: 'unset' }}
      >
        {children}
      </dialog>,
      document.body,
    );
  },
);
