import { useState } from 'react';
import { isFulfilled } from '@reduxjs/toolkit';
import { Button, Checkbox, Select } from '@sgme/ui';
import { useKey } from 'react-use';

import type { BreachCommentPost, BreachTypePatch } from '@/types/model/ws/generatedModel.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { patchBreach, postComment } from '@/store/async-thunks/api-thunks.ts';
import { TextInput } from '@/web/components/bootstrap/TextInput.tsx';
import {
  breachTypes,
  selectBreachType,
  selectCanPost,
  useCommentFormState,
} from '@/web/components/guardian/useCommentFormState.tsx';

export function CommentsForm({
  alertId,
  breachId,
  onCommentPosted,
  breachHasClosingComment,
}: {
  onCommentPosted: () => void;
  breachHasClosingComment: boolean;
  alertId: number;
  breachId: number;
}) {
  const dispatch = useAppDispatch();
  useKey(k => k.ctrlKey && k.key === 'Enter', handleSubmit);

  const [loading, setLoading] = useState(false);
  const [state, stateMethods] = useCommentFormState();

  const hasPermission = useAppSelector(state =>
    apiSlice.selectors.userHasPermission(state, 'WRITE'),
  );
  const canPost = selectCanPost(state, loading);
  const closeButtonEnabled = !breachHasClosingComment;
  const postButtonEnabled = canPost && hasPermission;

  async function patchBreachDispatch(body: BreachTypePatch): Promise<void> {
    const result = await dispatch(patchBreach({ alertId, breachId, body }));
    if (!isFulfilled(result)) {
      throw result;
    }
  }
  async function postCommentDispatch(body: BreachCommentPost): Promise<void> {
    await dispatch(postComment({ alertId, breachId, body }));
  }

  async function handleSubmit() {
    if (state.comment === '') {
      return;
    }

    setLoading(true);

    try {
      if (state.closingComment) {
        await patchBreachDispatch({ breachType: selectBreachType(state) });
      }
      await postCommentDispatch({
        comment: state.comment,
        closingComment: state.closingComment,
      });
    } finally {
      stateMethods.clearForm();
      setLoading(false);
      onCommentPosted();
    }
  }

  if (!hasPermission) {
    return null;
  }

  return (
    <article className="d-flex flex-column border-top pt-8px gap-8px">
      <textarea
        className="form-control"
        value={state.comment}
        onChange={e => stateMethods.setComment(e.target.value)}
      />
      <div className="d-flex gap-1">
        {state.closingComment && (
          <Select itemsAsObjects={breachTypes} onChange={stateMethods.setBreachType} />
        )}
        {state.closingComment && state.breachType === 'Other' && (
          <TextInput
            placeholder="Breach type..."
            name="breach-type"
            wrapperClassName="breach-type-input"
            maxLength={20}
            type="text"
            onChange={e => stateMethods.setFreeText(e.target.value)}
          />
        )}
      </div>

      <div className="flex-between">
        <Checkbox
          disabled={!closeButtonEnabled}
          checked={state.closingComment}
          onCheckBoxChange={closingComment => stateMethods.setClosing(closingComment)}
          label="Closing comment"
        />
        <Button
          disabled={!postButtonEnabled}
          onClick={handleSubmit}
          loading={loading}
          type="submit"
        >
          Send
        </Button>
      </div>
    </article>
  );
}
