import { useSyncExternalStore, type ReactNode } from 'react';
import { assertUnreachable } from '@sgme/fp';
import { Button, Tooltip } from '@sgme/ui';
import { useLoaderData } from '@tanstack/react-router';
import { addMonths, formatISO } from 'date-fns';

import type { Alert, Breach } from '@/types/model/ws/generatedModel.ts';
import { webApi } from '@/core/api/webApi.ts';
import { adjustForUTCOffset, formatDateTimeUtc } from '@/core/utils/dateFormats.ts';
import type { WsState } from '@/core/ws/WebsocketConnection.ts';
import { store } from '@/store/store.ts';
import { AlertsTable } from '@/web/components/guardian/AlertsTable.tsx';
import { BulkCloseButton } from '@/web/components/layout/BulkCloseButton.tsx';
import type { ExpandedDetails } from '@/web/components/layout/MainPage.tsx';
import { downloadCsv } from '@/web/download.ts';

interface TopPanelProps extends ExpandedDetails {}

export function TopPanel(props: TopPanelProps) {
  return (
    <>
      <ToolsPanel />
      <div className="grid-container">
        <AlertsTable {...props} />
      </div>
    </>
  );
}

function ToolsPanel() {
  return (
    <header>
      <section className="flex-between flex-grow-1 px-16px px-lg-24px mt-8px mb-16px">
        <div className="flex-center gap-3">
          <h1 className="h2">Real time alerts</h1>

          <WsStateIndicator />
        </div>

        <article className="d-flex gap-16px">
          {/*<button className="btn btn-flat btn-icon-end" type="button">*/}
          {/*  Reset display*/}
          {/*  <i className="icon">replay</i>*/}
          {/*</button>*/}
          {/*<div*/}
          {/*  className="btn-group btn-group-toggle btn-group-md"*/}
          {/*  data-toggle="buttons"*/}
          {/*  role="group"*/}
          {/*  aria-label="toggle buttons"*/}
          {/*>*/}
          {/*  <input*/}
          {/*    className="btn-check"*/}
          {/*    type="radio"*/}
          {/*    name="all"*/}
          {/*    id="all"*/}
          {/*    autoComplete="off"*/}
          {/*    defaultChecked*/}
          {/*  />*/}
          {/*  <label className="btn btn-toggle-primary" htmlFor="all">*/}
          {/*    All alerts*/}
          {/*  </label>*/}
          {/*  <input*/}
          {/*    type="radio"*/}
          {/*    className="btn-check"*/}
          {/*    name="active"*/}
          {/*    id="active"*/}
          {/*    autoComplete="off"*/}
          {/*  />*/}
          {/*  <label className="btn btn-toggle-primary" htmlFor="active">*/}
          {/*    Active*/}
          {/*  </label>*/}
          {/*</div>*/}
          <BulkCloseButton />
          <button className="btn btn-default btn-icon-end" type="button" onClick={exportCsv}>
            Export
            <i className="icon">save_alt</i>
          </button>
        </article>
      </section>
    </header>
  );
}

interface AlertSearchResult {
  alerts: (Alert & { breaches: Breach[] })[];
  totalPages: number;
  totalElements: number;
}

async function exportCsv() {
  const markets = store.getState().api.markets;
  const companies = store.getState().api.companies;

  const nowUtc = adjustForUTCOffset(new Date());
  const startDate = formatISO(addMonths(nowUtc, -1), { representation: 'date' });
  const endDate = formatISO(nowUtc, { representation: 'date' });
  const result = await webApi
    .get<AlertSearchResult>(
      `api/v1/guardian/alerts?page=1&pageSize=1000&minDate=${startDate}&maxDate=${endDate}`,
    )
    .json();

  function formatDate(date: string) {
    return formatDateTimeUtc(date);
  }

  const lines: string[] = result.alerts.flatMap(alert => {
    return alert.breaches.map(breach => {
      const market = markets.find(m => m.id === alert.key.marketId)?.name ?? alert.key.marketId;
      const company = companies.find(c => c.bdrId === alert.key.bdrId)?.name ?? alert.key.bdrId;
      const comments = breach.comments.reduce((acc, comment) => {
        const concatComment = `${comment.userName} ${formatDate(comment.instant)} ${comment.closingComment ? '(CLOSING)' : ''} ${comment.comment}`;
        if (acc === '') {
          return concatComment;
        }
        return `${acc} / ${concatComment}`;
      }, '');
      return `${alert.id};${company};${market};${alert.key.controlType};${alert.limitThreshold};${breach.id};${breach.status};${breach.worstSeverity};${breach.breachType ?? ''};${formatDate(breach.startInstant)};${breach.maxReached.percent};${breach.eventCount};${comments}`;
    });
  });

  lines.unshift(
    'alert ID;Company;Market;ControlType;Limit threshold;Breach ID;Status;Worst Severity;Breach type;Breach start time (UTC); Max reached (%);Event Count;Comments',
  );

  downloadCsv(
    'export.csv',
    lines.map(l => `${l}\n`),
  );
}

function WsStateIndicator() {
  const wsState = useWsConnected();
  // const router = useRouter();
  // const { wsConnection } = useLoaderData({ from: '/' });

  function handleRefresh() {
    // router.invalidate();
    window.location.reload();
  }

  const { tooltip, color, message } = getWsStatus(wsState, handleRefresh);
  return (
    <div>
      <Tooltip contents={tooltip}>
        <div className={`d-flex badge badge-discreet-${color} rounded-pill gap-1`}>
          <i className="icon">info_outline</i>
          <div className={`align-self-center text-${color} px-1`}>{message}</div>
        </div>
      </Tooltip>
      {/*<Button onClick={() => wsConnection.close()}>Close ws</Button>*/}
    </div>
  );
}

function getWsStatus(
  wsState: WsState,
  onRefresh: () => void,
): {
  tooltip: string;
  color: 'success' | 'danger' | 'warning';
  message: ReactNode;
} {
  switch (wsState) {
    case 'connected':
      return { tooltip: 'Connected', color: 'success', message: 'Connected' };
    case 'pending':
      return { tooltip: 'Pending', color: 'warning', message: 'Pending' };
    case 'closed':
      return {
        tooltip: 'Closed',
        color: 'danger',
        message: (
          <>
            Closed
            <Button purpose="link" onClick={onRefresh}>
              reload
            </Button>
          </>
        ),
      };
    default:
      assertUnreachable(wsState, '');
  }
}

function useWsConnected(): WsState {
  const { wsConnection } = useLoaderData({ from: '/' });

  function wsState() {
    return wsConnection.state;
  }

  function subscribe(callback: () => void) {
    wsConnection.eventEmitter.on('close', callback);
    wsConnection.eventEmitter.on('connected', callback);
    return () => {
      wsConnection.eventEmitter.off('close', callback);
      wsConnection.eventEmitter.off('connected', callback);
    };
  }

  return useSyncExternalStore(subscribe, wsState, wsState);
}
